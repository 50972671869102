.header{
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index : 10;
}
.nav-menu {
    display: flex;

}
.nav-menu li{
    padding: 0 1rem;
}
.nav-menu a{
    font-size: 1.2rem;
    font-weight: 500;
}
.hamburger{
    display : none;
}
@media screen and (max-width : 1240px) {
    .hamburger{
        display: block;
    }

    .nav-menu{
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top:0;
        left:-100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.3s;
        z-index: -1;
        background: rgba(0,0,0,.9);
    }
    .nav-menu.active{
        left : 0;
    }

    .nav-menu li{
        padding : 1rem 0;  
    }
    .nav-menu a{
        font-size: 2rem;
    }

}